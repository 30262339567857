

function NotFound () {

    return(

        <div className="containerCenterWeb">
            
            <div className="NicolasTwo">
                <h1>Pagina no Encontrada</h1>
            </div>

        </div>

    )

}

export default NotFound;